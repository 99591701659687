import React, { useCallback, useEffect } from 'react';
import { useIsSmallViewportWidth } from '@hqo/react-components-library/dist/viewport';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getCurrentServiceRequest } from 'store/serviceRequests/actions';
import { DetailsMobilePage } from 'pages/details/details-mobile-page';
import { DetailsDesktopPage } from 'pages/details/details-desktop-page';
import { replace } from 'connected-react-router';
import { ROOT_PATH } from 'utils/constants';

export const DetailsPage: React.FC = (): JSX.Element => {
  const isMobile = useIsSmallViewportWidth();
  const dispatch = useDispatch();
  const { requestId } = useParams<{ requestId: string }>();

  const handleClose = useCallback(() => {
    dispatch(replace(`${ROOT_PATH}`));
  }, [dispatch]);

  useEffect(() => {
    dispatch(getCurrentServiceRequest.request(requestId));
  }, [dispatch, requestId]);

  if (isMobile) {
    return <DetailsMobilePage onClose={handleClose} />;
  }

  return <DetailsDesktopPage onClose={handleClose} />;
};
